import { CelebrationCyclingAnimationLoadingSpinner } from '@xo-health-web/shared-module';
import ErrorImage from 'assets/error-rubiks-cube.svg';
import FeatureHighlightsBackground from 'assets/feature-highlights-background.png';
// Wallet asset overrides
import ClaimsEntry from 'assets/claims-entry-icon.svg';
import EmptyState from 'assets/empty-state-activites.svg';
import EmptyStateConfigurableActivities from 'assets/empty-state-configurable-activites.svg';
import ReachedProgramLimit from 'assets/reached-program-limit.svg';
import ConnectApps from 'assets/connect-apps.svg';
import NoData from 'assets/no-data.svg';
import ExitWarning from 'assets/exit-warning.svg';

const COMMON_OVERRIDES = {
  WEB_COMMON_ERROR_STATE_IMAGE: ErrorImage,
};

const ONBOARDING_OVERRIDES = {
  FEATURE_HIGHLIGHT_BACKGROUND: FeatureHighlightsBackground,
};

const WALLET_OVERRIDES = {
  CLAIMS_ENTRY: ClaimsEntry,
};

const JOURNEY_ASSETS = {
  EMPTY_STATE_ACHIEVEMENTS: EmptyState,
  EMPTY_STATE_HISTORY: EmptyState,
  EMPTY_STATE_PROGRAMS: EmptyStateConfigurableActivities,
  EMPTY_STATE_CONFIGURABLE_ACTIVITIES: EmptyStateConfigurableActivities,
};

const HEALTH_PROGRAMS_ASSETS_MAP = {
  MAX_PROGRAM_ENROLMENT_IMAGE: ReachedProgramLimit,
};

const HEALTH_PROGRAMS_ASSET_KEYS = {
  DEVICE_CONNECTION_ERROR_ICON: ConnectApps,
};

const DASHBOARD_ASSETS = {
  DASHBOARD_NO_HEALTH_METRIC_DATA: NoData,
};

const HEALTH_JOURNEY_COMMON_ASSET_MAP = {
  ACTIVITIES_CYCLING_ANIMATION: CelebrationCyclingAnimationLoadingSpinner,
};

const CLAIMS_OVERRIDES = {
  CLAIMS_LIST_EMPTY: ErrorImage,
};

const HEALTH_JOURNEY_ASSET_KEYS = {
  WAVE_GOODBYE: ExitWarning,
};

export const ASSET_OVERRIDES = {
  ...DASHBOARD_ASSETS,
  ...ONBOARDING_OVERRIDES,
  ...COMMON_OVERRIDES,
  ...WALLET_OVERRIDES,
  ...JOURNEY_ASSETS,
  ...HEALTH_PROGRAMS_ASSETS_MAP,
  ...HEALTH_PROGRAMS_ASSET_KEYS,
  ...HEALTH_JOURNEY_COMMON_ASSET_MAP,
  ...HEALTH_JOURNEY_ASSET_KEYS,
  ...CLAIMS_OVERRIDES,
};
