import { useState } from 'react';
import {
  LoadingSpinner,
  UserSetupFormComponent,
  ErrorState,
  ERROR_TYPES,
} from '@xo-health-web/shared-module';
import { useGetUserProfile } from '@leagueplatform/web-common';
import { BasePage } from 'components/BasePage';
import { OnboardingFeatureHighlights } from 'components/onboarding-feature-highlights/onboarding-feature-highlights.component';
import completion from 'assets/completion.svg';
import logoWithText from 'assets/logo-with-text.png';
import { useOnboarding } from 'hooks/use-onboarding.hook';
import { PersonalInformationPage } from 'pages/PersonalInformationPage';

export const PrivateRoutesContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    data: userData,
    isLoading: isLoadingUserProfile,
    isError: isErrorUserProfile,
    refetch,
  } = useGetUserProfile();

  const hasUserAcceptedTerms =
    userData?.userProfile?.memberTermsAndConditions?.dateAccepted;

  const [showPersonalInformation, setShowPersonalInformation] =
    useState<boolean>(false);

  const handleInfoExit = () => {
    setShowPersonalInformation(false);
  };

  const {
    onOnboardingComplete,
    shouldShowFeatureHighlights,
    isLoading: isOnBoardingLoading,
  } = useOnboarding();

  const isLoading = isOnBoardingLoading || isLoadingUserProfile;

  if (isErrorUserProfile)
    return <ErrorState isFullPage errorType={ERROR_TYPES.API_ERROR} />;

  const handleExit = () => {
    setShowPersonalInformation(true);
    refetch();
  };

  if (isLoading) {
    return <LoadingSpinner logo={logoWithText} altText="XO_HEALTH_LOGO" />;
  }
  if (shouldShowFeatureHighlights) {
    return (
      <OnboardingFeatureHighlights
        onOnboardingComplete={onOnboardingComplete}
      />
    );
  }

  if (!hasUserAcceptedTerms) {
    return (
      <UserSetupFormComponent formImage={completion} handleExit={handleExit} />
    );
  }
  if (showPersonalInformation) {
    return (
      <PersonalInformationPage
        showPersonalInformation={showPersonalInformation}
        handleInfoExit={handleInfoExit}
      />
    );
  }
  return <BasePage>{children}</BasePage>;
};
